import React from 'react';
import './Tutorials.css';
import TileLarge from '../../Tile/Tile_tutorials/Tile_large/Tile_tutorial_large';
import { withRouter, Route, Link } from 'react-router-dom';
import dataset from '../../../assets/Data/Tutorials.json';


const tutorials = (props) => {
        return(
            <div>
            <h2>Tutorials</h2>
            <div className="title-separation"></div>


            {dataset.tutorials.map((tutorials, index) => {

            if (tutorials.id === props.match.params.id){
                return(

                    <TileLarge
                        title = {tutorials.title}
                        language = {tutorials.language}
                        data = {tutorials.data}
                        category = {tutorials.category}
                        date = {tutorials.date}
                        tile = {tutorials.tile}
                        content = {tutorials.content}
                        crossclicked ={() => props.crossclicked()}
                        copyclip ={(text) => props.copyclip(text)}
                    />
                )
                }
            })
        }
        <br/>
        </div>   
        )  


};

export default withRouter(tutorials);