import React from 'react';
import dataset from '../../../../assets/Data/Additions.json';
import Tile from '../../../Tile/Tile_additions/Tile_additions';
import { Link, withRouter } from 'react-router-dom';

const additions= (props) =>  {
    
    return (
        <div >

        
        {dataset.additions.map((additions, index) => {
            if (index < props.n){
                if (additions.type == "About Me") {
                    return (

                        <Link to={"/About"} >
                            <Tile type={additions.type} 
                            tile_type={additions.tile} 
                            title={additions.title} 
                            link={additions.link}
                            when={additions.when}
                            /> 
                        </Link>
                            
                        )
                    
                } else {
                    return (

                    <Link to={"/" + additions.type +"s"} >
                        <Tile type={additions.type} 
                        tile_type={additions.tile} 
                        title={additions.title} 
                        link={additions.link}
                        when={additions.when}
                        /> 
                    </Link>
                        
                    )
                }
            }
        })
        
        }
        </div>

        



    )
};
export default withRouter(additions);