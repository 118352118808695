
import React from 'react';
import Tile from '../../../Tile/Tile_blog/Tile_blog';
import dataset from '../../../../assets/Data/Blogs.json';
import { withRouter  } from 'react-router-dom';

const blogs= (props) =>  {
    

    return (
        <div >
        <h3>Blogs and short communications </h3>
        {dataset.blogs.map((blogs, index) => {

        return (
        <Tile 
        type={blogs.type} 
        tile_type={blogs.tile} 
        title={blogs.title} 
        long_title={blogs.long_title}
        link={blogs.link}
        short_description={blogs.short_description}
        /> 
            
        )
        })
        }

        </div>



    )
};
export default withRouter (blogs);