import React from 'react';
import './Menu_drawer.css';
import {Nav} from 'react-bootstrap';
import logo from '../../../assets/Pics/BDS_transparent_green2.png';
import { withRouter, Route, Link } from 'react-router-dom';
import classes from './Menu_drawer.css';
import { AiOutlineHome, AiOutlineBank, AiOutlineCode, AiOutlineRead, AiOutlineQuestion} from 'react-icons/ai';


const menu_drawer = (props) =>  {

    let page = props.location.pathname.replaceAll('/', '');
    page = page.replace(/[0-9]/g, "")

    if (page === "Talks" |  page === "Blogs" ){
        page = "Resources"
    } else if (page === "Papers" |  page === "Projects" ) {
        page = "Research"
    } else if (page === ""){
        page ="Home"
    }


    return (
    <div className={classes.SideDrawer} >
    <div class="SideDrawer" align="center">

        <img src={logo} alt="Bright Data Sci." width="50" ></img>
        <div className="spacer" />
        <Nav activeKey={page} className="flex-column">
        {props.pages.map((page, index) => {

            if (page.name === "Home"){
                return(
                    <Nav.Link eventKey={page.name} key={page.name} onClick={() => props.clicked(page.name)} > <Link to = "/"  ><AiOutlineHome /></Link></Nav.Link>
                    )
            } else if (page.name === "About me") {
                return(
                    <Nav.Link eventKey="About" key={page.name} onClick={() => props.clicked(page.name)} > <Link to = {"/About"} ><AiOutlineQuestion /></Link></Nav.Link>
                    )

            } else if (page.name === "Research") {
                return(
                    <Nav.Link eventKey={page.name} key={page.name} onClick={() => props.clicked(page.name)} > <Link to = {"/" + page.name} ><AiOutlineBank /></Link></Nav.Link>
                    )

            } else if (page.name === "Tutorials") {
                return(
                    <Nav.Link eventKey={page.name} key={page.name} onClick={() => props.clicked(page.name)} > <Link to = {"/" + page.name} ><AiOutlineCode /></Link></Nav.Link>
                    )

            } else if (page.name === "Resources") {
                return(
                    <Nav.Link eventKey={page.name} alt={page.name} key={page.name} onClick={() => props.clicked(page.name)} > <Link to = {"/" + page.name} ><AiOutlineRead /></Link></Nav.Link>
                    )
            }
            
        })}
        </Nav>

    </div>
    </div>

    )};
export default withRouter(menu_drawer);