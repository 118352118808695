
import React from 'react';
import './AboutMe.css';
import pic from '../../../assets/Pics/fred_tsp_anim_start_later.gif';
import { FiFileText, FiMail } from 'react-icons/fi';


const aboutme = (props) =>  {

    return (
        <div className="PageContainer">
        <h2>About Me </h2>
        <div className="title-separation"></div>
        <div align="left" ClassName="about">
            <div class="icon">
                <a href="mailto:frederique.bone@isi.fraunhofer.de" rel="noreferrer" target="_blank" > 
                  <FiMail size={30} color="#000000" />
                </a>
{/*                 <a href="https://github.com/Frederique85" rel="noreferrer" target="_blank" > 
                  <FiFileText size={30} color="#000000" style={{ marginLeft: 12 }}/>
                </a> */}



            </div>
            <img src={pic} alt="F_Bone_TSP" width="240"  className = "picture"></img>
            <p className="top">My name is Frédérique Bone and I am a senior <b>researcher at the <a className="colorblue" href="https://www.isi.fraunhofer.de/en.html" rel="noreferrer" target="_blank">Fraunhofer ISI</a></b>, an applied research institute focusing on systems and innovation research, in Karlsruhe, Germany.
            I have previously worked at <a className="colorblue" href="http://www.sussex.ac.uk/spru/" rel="noreferrer" target="_blank">Science Policy Research Unit (SPRU)</a> at the University of Sussex and am still research associate there.
            From 2021 to summer 2022, I was seconded to the Medical Research Council (MRC) <a className="colorblue" href="https://mrc.ukri.org/successes/evaluating-research-outcomes/" rel="noreferrer" target="_blank" >evaluation and analysis team</a>. 
            My research aims at understanding how technologies get developed and find their way into our lives.
            More specifically, my research focuses on how technologies emerge and diffuse within the economy and society. 
            I have also studied the contribution of public research to innovation extensively.  
            I specialise in both the biomedical sector and artificial intelligence.</p>

            <p> In my work, I attach particular importance in thinking about the way methods shape the evidence generated to support scientific claims. 
                This features in my work on research evaluation, where the indicators and evidence used in turn shape the behaviour of those evaluated. 
                Thus, <b>I work with both quantitative and qualitative methods</b>, where the quantitative aspect brings a systematic but stylised version of reality; 
                and the qualitative brings all important context to interpret the results. 
                One example of work mixing methods is the development of the <a className="colorblue" href="http://www.sussex.ac.uk/spru/research/dare/index.html" rel="noreferrer" target="_blank">DARE method</a> looking at research collaboration in biomedical projects. 
            </p>

            <p>
            My interest in methods and artificial intelligence led me to develop my digital skills for research. 
            I love developing my programming skills to build bespoke datasets from data available on the web;
            to analyse data using a variety of statistical and machine learning tools; and to visualise data to communicate results effectively. 
            I have worked recently on a <a className="colorblue" href="https://deeptransitions.net/2020/11/23/how-to-identify-rules-from-text-mining/" rel="noreferrer" target="_blank">large text-mining analysis of <i>mass production</i></a> over 150 years in the Deep Transitions project.
            </p>

            <p>
            My work on data science led me to develop and teach a module for the <a className="colorblue" href="https://www.sussex.ac.uk/study/masters/courses/science-and-technology-policy-msc" rel="noreferrer" target="_blank" >Science and Technology Policy MSc</a>,
            which aims at <b>teaching data science tools applied to innovation studies</b>. 
            Based on these experiences, I have added a section on my website dedicated to the use of data science to study the research and innovation system in the tutorials section.
            </p>

            
        </div>
        </div>

    )
};



export default aboutme;




