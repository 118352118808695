import React from 'react';
import './Tile_talk.css';
import { FiFileText, FiMonitor} from 'react-icons/fi';

const tile = (props) =>  {
    let page_content = null;
    let content =null;

        if (props.link===""){
            page_content = (<p className={"firstp"}> Where: {props.where} </p>)
        } else {
            page_content = (<p className={"firstp"}> Where: <a href={props.link}>{props.where}</a> </p>)
        }

    
        if (props.paper!=="" & props.presentation!==""){

            content = (
                <div>
                <a href={props.paper}  rel="noreferrer" target="_blank" download> 
                    <FiFileText size={20}/>
                </a>
                <a href={props.presentation}  rel="noreferrer" target="_blank" download> 
                    <FiMonitor size={20} />
                </a>
                </div>)

        } else if (props.paper!==""){
            content = (
                <div>
                <a href={props.paper} rel="noreferrer" target="_blank" download> 
                    <FiFileText size={20} />
                </a>
                </div>)

        } else if (props.presentation!==""){
            content = (
                <div>
                <a href={props.presentation} rel="noreferrer" target="_blank" download> 
                    <FiMonitor size={20} />
                </a>
                </div>)
        } 

    return (

        <div className={props.tile_type}>
            <div >
            <div className ="pic">
            {content}
            </div>

                <h3> {props.title} </h3>
                <p className={"secondp"}> When: {props.when} </p>
                {page_content}
                
                <p></p>
            </div>
                
        </div>


    )
};



export default tile;