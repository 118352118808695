import React from 'react';
import Talks from './Talks/Talks';
import Blogs from './Blogs/Blogs';
import Submenu from '../../Navigation/Submenu/Submenu';
import { withRouter , Route } from 'react-router-dom';

const resources= (props) =>  {


    return (
        <div >
        <h2>Resources </h2>
        <div className="title-separation-long"></div>
        <div> 
            <Submenu sub={props.sub} activesub={props.activesub} clicked ={(item) => props.clicked(item)}/>
        </div>
        
        <Route path="/Resources" exact component={Talks}/>
        <Route path="/Talks" exact component={Talks}/>
        <Route path="/Blogs" exact component={Blogs}/>
        

        </div>



    )
};
export default withRouter(resources);