import React from 'react';
import Tile from '../../../Tile/Tile_project/Tile_project';
import dataset from '../../../../assets/Data/Projects.json';
import { withRouter , Route } from 'react-router-dom';

const projects= (props) =>  {

    console.log(dataset)
    
    return (
        <div >
        <div className="PageContainer">
                <p>Find here research projects that I have been involved in.</p>
                <h3>Projects </h3>
        {dataset.projects.map((projects, index) => {

        return (
        

        <Tile tile_type={projects.tile} 
        title={projects.title} 
        long_title={projects.long_title}
        link={projects.link}
        role={projects.role}
        funder={projects.funder}
        short_description={projects.short_description}
        start={projects.start}
        end={projects.end}
        authors={projects.end}
        /> 
            
        )
        })
        }
        
        </div>
        </div>



    )
};
export default withRouter(projects);
