import React from 'react';
import './Tile_paper.css';
import { FiDownload, FiExternalLink} from 'react-icons/fi';

const tile = (props) =>  {

    return (

        <div className={props.tile_type}>
            <div>
 
                <h3> {props.title} </h3>
                <p className={"second_paper_p"}>  
                    <a href={props.link} rel="noreferrer" target="_blank" className = "icon_paper"> 
                        <FiExternalLink size={20} />
                    </a>
                    <a href={props.download} rel="noreferrer" target="_blank" className = "icon_paper"> 
                        <FiDownload size={20} />
                    </a>
                </p>
                <p className={"first_paper_p"}>{props.journal} ({props.year})</p>
                
                <p></p>
            </div>
                

        </div>


    )
};



export default tile;