import React from 'react';
import './Tile_additions.css';
import { FiExternalLink} from 'react-icons/fi';

const tile = (props) =>  {
    let content =null;
    if (props.link !==""){
        content = (<a href={props.link} rel="noreferrer" target="_blank" className = "icon_add"> 
        <FiExternalLink size={20} />
    </a>)
    }

    return (

        <div className={`tilead${props.tile_type}`}>
            <div>
 
                <h3> [{props.type}] - {props.title} </h3>
                <p className={"second_add_p"}>  
                    {content}
                </p>
                <p className={"first_add_p"}>Added: {props.when}</p>
                
                <p></p>
            </div>
                

        </div>


    )
};



export default tile;