import React, { Component } from 'react';
import './App.css';
import Menu from './components/Navigation/Menu/Menu';
import MenuDrawer from './components/Navigation/Menu_drawer/Menu_drawer';
import Toggle from './components/Navigation/Menu_drawer/Toggle';
import TopIcons from './components/TopIcons/TopIcons';
import AboutMe from './components/Pages/AboutMe/AboutMe';
import Home from './components/Pages/Home/Home';
import HomeMobile from './components/Pages/Home/Home_mobile';
import Resources from './components/Pages/Resources/Resources';
import Tutorials from './components/Pages/Tutorials/Tutorials';
import TutorialsPost from './components/Pages/Tutorials/Tutorial_post';
import Research from './components/Pages/Research/Research';
import {Col, Row, Container} from 'react-bootstrap';
import { HashRouter, Switch, Route } from 'react-router-dom';


class App extends Component{

  state = {
    pages: [
      {name:'Home' , header: 'Home',
      submenu: ''},
      {name:'Research' , header: 'Research',
      submenu: ['Papers', 'Projects']},
      {name:'Tutorials' , header: 'Tutorials',
      submenu: ''},
      {name:'Resources' , header: 'Resources',
      submenu: ['Talks', 'Blogs']},
      {name:'About me' , header: 'About me',
      submenu: ''}
    ],
    activepage: 'Home',
    subpage: [''],
    activesubpage: '',
    tutorial:"small",
    tutorial_prop:{ 'title': "" ,
    'language': "",
    'data': "",
    'category': "",
    'date': "",
    'tile': "1",
    'content': ""},
    showSideDrawer: false
  }

  addActive = (itemIndex) => {

    this.setState({
      activepage: itemIndex
    });

    this.setState({
      tutorial:"small"

    });

    this.state.pages.map((item) => {
      
      if (item.name === itemIndex){
        this.setState({
          subpage: item.submenu
        });
        this.setState({
          activesubpage: item.submenu[0]
        });
      }
      
    })

  }

  addSubActive = (itemIndex) => {
    this.setState({
      activesubpage: itemIndex
    });

  }

  showTutorial = (itemIndex) => {
    this.setState({
      tutorial_prop: itemIndex

    });

    this.setState({
      tutorial:"large"

    });

  }

  hideTutorial = (itemIndex) => {

    this.setState({
      tutorial:"small"

    });

  }

  copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);

  }

  drawerToggleHandler = () => {
    this.setState((prevState) => {
      return {showSideDrawer: !prevState.showSideDrawer};
      });
  }

  render() {

    let page = this.state.activepage;   
    let subpage = this.state.subpage;
    let activesubpage = this.state.activesubpage;
    let page_content = null;

    return (
      <HashRouter basename="/">
      <div className="App">
        <Container>
          <Row>
            
          {/* Navbar has to stay on every rows */}
            <Col xs="2">
              <Switch>
              <Menu 
              activepage = {this.state.activepage} 
              pages= {this.state.pages} 
              clicked = {this.addActive} />
              </Switch>

              <Switch>
              <MenuDrawer 
              activepage = {this.state.activepage} 
              pages= {this.state.pages} 
              clicked = {this.addActive} />
              </Switch>
            </Col>

          {/* this is the main page where the content is dynamic */}
            <Col xs="10">
              
              <TopIcons />
              <Switch>
              <Route path="/" exact>
                <Home clicked = {this.addActive} /> 
                <HomeMobile clicked = {this.addActive} /> 
              </Route>
              <Route path="/Tutorials" exact>
                <Tutorials 
                name={this.name}
                tutorial_prop = {this.state.tutorial_prop} 
                clicked = {this.showTutorial}/>        
              </Route>
              <Route path="/Research" exact>
                <Research name="Research" sub={['Papers', 'Projects']} activesub="Papers" clicked = {this.addSubActive}/>   
              </Route>

              <Route path="/Papers" exact>
                <Research name="Research" sub={['Papers', 'Projects']} activesub="Papers" clicked = {this.addSubActive}/>   
              </Route>

              <Route path="/Projects" exact>
                  <Research name="Research" sub={['Papers', 'Projects']} activesub="Projects" clicked = {this.addSubActive}/> 
              </Route>
              <Route path="/Resources" exact>
                <Resources sub={['Talks', 'Blogs']} activesub="Talks" clicked = {this.addSubActive}/>  
              </Route>
              <Route path="/About" exact>  
                <AboutMe/>
              </Route>
              <Route path="/Resources" exact>
                <Resources sub={['Talks', 'Blogs']} activesub="Talks" clicked = {this.addSubActive}/>  
              </Route>
              <Route path="/Talks" exact>
                <Resources sub={['Talks', 'Blogs']} activesub="Talks" clicked = {this.addSubActive}/> 
              </Route>
              <Route path="/Blogs" exact>
                <Resources sub={['Talks', 'Blogs']} activesub="Blogs" clicked = {this.addSubActive}/>  
              </Route>
              <Route path="/Tutorials/:id">
                <TutorialsPost
                tilesize={this.state.tutorial} 
                tutorial_prop = {this.state.tutorial_prop} 
                clicked = {this.showTutorial}
                crossclicked =  {this.hideTutorial} 
                copyclip = {this.copyToClipboard}/>        
              </Route>
              <Route path="/">
                <Home clicked = {this.addActive} /> 
              </Route>
            </Switch>
            </Col>
          </Row>
        </Container>
      </div>
      </HashRouter>
    );
  }
}
export default App;
