import React from 'react';
import './Tile_blog.css';
import { FiExternalLink} from 'react-icons/fi';

const tile = (props) =>  {

    return (

        <div className={`tilebl${props.tile_type}`}>
            
            <div>
                <div className={"second"}>
                    <a href={props.link} rel="noreferrer" target="_blank" className = "pic_blog"> 
                            <FiExternalLink size={20} />
                    </a>
                </div>
                <div className={"first"}>
                    <h3> [{props.type}] {props.title} -</h3>
                    <h4> {props.long_title} </h4>
                </div>

            </div>
            <div>
                <div className={`blog-separation${props.tile_type}`}></div>
                <p className={"first_blog_p"}>{props.short_description}</p>
                
            </div>
            <p></p>      

        </div>


    )
};



export default tile;