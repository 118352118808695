import React from 'react';
import './Menu.css';
import {Nav} from 'react-bootstrap';
import logo from '../../../assets/Pics/BDS_transparent_green2.png';
import { withRouter, Route, Link } from 'react-router-dom';
import classes from './Menu.css';


const menu = (props) =>  {

    let page = props.location.pathname.replaceAll('/', '');
    page = page.replace(/[0-9]/g, "")

    if (page === "Talks" |  page === "Blogs" ){
        page = "Resources"
    } else if (page === "Papers" |  page === "Projects" ) {
        page = "Research"
    } else if (page === ""){
        page ="Home"
    }

    return (
    <div className={classes.sidenav} > 
    <div class="sidenav" align="center">

        <img src={logo} alt="Bright Data Sci." width="140" ></img>
        <div className="spacer" >
            <div></div>
        </div>
        
        <Nav activeKey={page} className="flex-column">
        {props.pages.map((page, index) => {

            if (page.name === "Home"){
                return(
                    <Nav.Link eventKey={page.name} key={page.name} onClick={() => props.clicked(page.name)} > <Link to = "/"  >{page.name}</Link></Nav.Link>
                    )
            } else if (page.name === "About me") {
                return(
                    <Nav.Link eventKey="About" key={page.name} onClick={() => props.clicked(page.name)} > <Link to = {"/About"} >About me</Link></Nav.Link>
                    )

            } else {
                return(
                    <Nav.Link eventKey={page.name} key={page.name} onClick={() => props.clicked(page.name)} > <Link to = {"/" + page.name} >{page.name}</Link></Nav.Link>
                    )
            }
            
        })}
        </Nav>

        <p>By <br />Frédérique Bone<br /></p>
    </div>
    </div>

    )};
export default withRouter(menu);
