import React from 'react';
import Tile from '../../../Tile/Tile_paper/Tile_paper';
import dataset from '../../../../assets/Data/Papers.json';
import { withRouter , Route } from 'react-router-dom';

const papers= (props) =>  {

    console.log(dataset)
    
    return (
        <div className="PageContainer">
            <p>Find here my latest papers, working papers, and conference proceedings. </p>
            <h3>Papers </h3>

        {dataset.papers.map((papers, index) => {

        return (

        <Tile 
        tile_type={papers.tile} 
        title={papers.title} 
        journal={papers.journal}
        link={papers.link}
        download={papers.download}
        year={papers.year}
        authors={papers.authors}
        />   
        )
        })
        }
        

        </div>



    )
};
export default withRouter(papers);
