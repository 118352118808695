import React from 'react';
import './Home.css';
import HomeTile from '../../Tile/Tile_home/Tile_home';
import LatestAdditions from './Latest_additions/Latest_additions';
import { Link, withRouter } from 'react-router-dom';


const home = (props) =>  {
    const items = {
        item: [
          {name:'Research' , 
          style: '1',
          text: 'Find out more about the research projects that I have been involved in and my published and working papers. ',
          icon:'research'},

          {name:'Tutorials' , 
          style: '2',
          text: 'Learn to program with R in order to collect, clean and analyse data with a specific examples from innovation studies. ',
          link:'research'},

          {name:'Resources' , 
          style: '1',
          text: 'Find out more about conference talks that I am giving (including links to presentations) or blogs that I have written. ',
          link:'research'}
        ]
      };

    return (
        <div class="SmallSize">
         <h2>Home </h2>
        <div className="title-separation"></div>

        <div> 
            {items.item.map((item, index) => {
                  return(
                    <div>
                    <div>
                    <Link to={"/" + item.name} onClick = {() => props.clicked(item.name)} >
                    <HomeTile style={item.style} 
                    name={item.name} 
                    text={item.text} 
                    
                    />
                  </Link>
                  </div>
                  </div>
                  )
                  
                })}
        
        </div>


        <div>
        <Link to = {"/About"} >
            <div class="about">
            <div>
              <h3> About me</h3>
              <p>I am <b>Frédérique Bone</b>,
              a Research Fellow at SPRU at the University of Sussex,
              Director of the Science & Technology Policy MSc, 
              currently seconded to the MRC Evaluation and Analysis team.
              My main field of expertise lies within science policy and 
              technological evolution, focusing on Biotechnology and Artificial Intelligence.</p>
            <p></p>
            </div>
            </div>
            </Link>

        </div>
        <div>
        <div className="title-separation-section"></div>
          <h3> Latest additions</h3>
          <div className="title-separation"></div>

          <div class="homesecondsmall" >
            <div className="additions">
              <LatestAdditions n={8}/>
            </div>

          </div>
        </div>
            
        </div>


    )
};



export default withRouter(home);




