import React from 'react';
import { FiLinkedin,  FiGithub, FiTwitter} from 'react-icons/fi';

const topicons = (props) =>  {

    return (    
        <div class="mainpage">

        <div align="right" >
      
          <a href="https://github.com/Frederique85" rel="noreferrer" target="_blank"> 
            <FiGithub size={35} color="#333344" />
          </a>
          <a href="https://twitter.com/FBone11" rel="noreferrer" target="_blank">
            <FiTwitter size={35} color="#333344" style={{ marginLeft: 12 }}/>
          </a>
          <a href="https://www.linkedin.com/in/frédérique-bone-5832b031/" rel="noreferrer" target="_blank">
            <FiLinkedin size={35} color="#333344" style={{ marginLeft: 12 }}/>
          </a>

        </div>   
      </div>

    )};
export default topicons;