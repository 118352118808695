import React from 'react';
import './Tile_tutorial_small.css';

const tile = (props) =>  {

    let property = { 
    'id': props.id ,
    'title': props.title ,
    'language': props.language,
    'data': props.data,
    'category': props.category,
    'date': props.date,
    'tile': props.tile,
    'content': props.content}
        
    return (
        <div class={`tiletut${props.tile}`} onClick={() => props.clicked(property)}>
            <div>
                <h3> {props.title} </h3>
                <p className={"second_tutorial_p"}>Added: {props.date}</p>
                <p className={"first_tutorial_p"}>Language : {props.language} - Data: {props.data} - Category: {props.category} <br/>
                </p>
            </div>
                

        </div>
    )
};



export default tile;