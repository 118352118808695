
import React from 'react';
import './Tile_home.css';
import { Link, withRouter } from 'react-router-dom';

const home_tile= (props) =>  {
    let top = "tile1top";
    let bottom = "tile1bottom";

    if (props.style==="1"){
        top = "tile1top";
        bottom = "tile1bottom";
    } else if  (props.style==="2"){
        top = "tile2top";
        bottom = "tile2bottom";
    } else {
        top = "tile3top";
        bottom = "tile3bottom"; 
    }
    
    return (

        <div class={`parent${props.style}`}>
            

                <div class={top} >
                    <h2>{props.name}</h2>
                </div>

                <div class={bottom}>
                    <p>{props.text}</p>
                </div>

            
        </div>

    )
};
export default home_tile;
