import React, { lazy , Suspense } from 'react';
import './Tile_tutorial_large.css';
import { FiX } from 'react-icons/fi';
import { Link , withRouter } from 'react-router-dom';

const tile = (props) =>  {

    const BodyTutorial = lazy(()=> import("../../../../assets/Tutorials/"+props.content ));
    
    return (
        
        <div class={`tiletutl${props.tile}`} >

            <div class="head">
            <Link to="/Tutorials">
                <div class="tryright">
                        <FiX color='black' size={20}/>
                </div>
            </Link> 
                <h3> {props.title} </h3>
                <p className={"second_tutoriall_p"}>Added: {props.date}</p>
                <p className={"first_tutoriall_p"}>Language : {props.language} - Data: {props.data} - Category: {props.category} <br/>
                </p>
            </div>

            <div class="body">
                <br/>
                <Suspense fallback={<div>Page is Loading...</div>}> 
                    <BodyTutorial copyclip={(text) => props.copyclip(text)}/>
                </Suspense>
            </div>
                

        </div>
    )
};



export default withRouter(tile);