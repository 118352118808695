import React from 'react';
import './Tutorials.css';
import dataset from '../../../assets/Data/Tutorials.json';
import TileSmall from '../../Tile/Tile_tutorials/Tile_small/Tile_tutorial_small';
import { withRouter, Route, Link } from 'react-router-dom';


const tutorials = (props) => {

        return (
            <div>
                <h2>Tutorials</h2>
                <div className="title-separation"></div>
                <div>
                    <p>This section features some data mining and data science tutorials, inspired by my teaching and research.</p>
                </div>
    
    
    
            {dataset.tutorials.map((tutorials, index) => {
 
            return(
                    <Link to={"/Tutorials/" + tutorials.id} name="Tutorials">

                    <TileSmall 
                    id = {tutorials.id}
                    title = {tutorials.title}
                    language = {tutorials.language}
                    data = {tutorials.data}
                    category = {tutorials.category}
                    date = {tutorials.date}
                    tile = {tutorials.tile}
                    content = {tutorials.content}
                    clicked ={(item) => props.clicked(item)}
                    /> 
                    </Link>

            )       
            })
            }
            </div>
    
        )



};

export default withRouter(tutorials);