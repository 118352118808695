
import React from 'react';
import Submenu from '../../Navigation/Submenu/Submenu';
import Papers from './Papers/Papers';
import Projects from './Projects/Projects';
import { withRouter , Route } from 'react-router-dom';


const research = (props) => {


    let page = props.activesub;   
    

    return (
        <div class="research" align="left">
        <h2>Research </h2>
        <div className="title-separation-long"></div>

                    <div> 
                        <Submenu sub={props.sub} activesub={page} clicked ={(item) => props.clicked(item)}/>
                        <Route path="/Research" exact component={() =>
                          <Papers />
                        }/>
                        <Route path="/Papers" exact render={() =>
                          <Papers />
                        }/>
                        <Route path="/Projects" exact render={() =>
                          <Projects />
                        }/>

                    </div>





        </div>

    )

}
export default withRouter(research);