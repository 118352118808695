import React from 'react';
import './Submenu.css';
import { withRouter, Route, Link } from 'react-router-dom';

const submenu = (props) => {
    let menu_item = props.sub;

    // for the ones that have a submenu, enter manually what is the content of that menu
    return (
        <div class="subnav" align="center">
            
                {menu_item.map((item, index) => {
                    return(
                    <div >
                        { (item === props.activesub) && <a className="highlight"><Link to = { "/"+item}  >{item}</Link></a>}
                        { (item !== props.activesub) && <a onClick={() => props.clicked(item)}><Link to = {"/" +item}  >{item}</Link></a>}
                        { (index !== (menu_item.length-1)) && <div class="menu-separator"></div>}
                        
                    </div>
                    )     
                })}

            <div class="title-separation-long"></div>
        </div>
    )

};

export default withRouter(submenu);
