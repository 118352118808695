import React from 'react';
import './Tile_project.css';

const tile = (props) =>  {

    return (

        <div className={`tilepr${props.tile_type}`}>
            
            <div>
                <h3> {props.title} -</h3>
                <h4> {props.long_title} </h4>
            </div>
            <div className={`project-separation${props.tile_type}`}></div>
            <div>
                <p className={"second_project_p"}>  
                ({props.start} - {props.end})
                </p>
                <p className={"first_project_p"}>Role: {props.role}</p>
                <p className={"first_project_p"}>Funder: {props.funder}</p>
                <div className={`project-separation${props.tile_type}`}></div>
                <p className={"first_project_p"}>{props.short_description}</p>
                <br/>
                
            </div>
            <p></p>      

        </div>


    )
};



export default tile;