
import React from 'react';
import Tile from '../../../Tile/Tile_talk/Tile_talk';
import dataset from '../../../../assets/Data/Talks.json';
import { withRouter  } from 'react-router-dom';

const talks= (props) =>  {
    

    return (
        <div >
        <h3>Talks and events </h3>
        {dataset.talks.map((talks, index) => {

        return (
        <Tile tile_type={talks.tile} 
        title={talks.title} 
        where={talks.where}
        link={talks.link}
        when={talks.when}
        paper={talks.paper}
        presentation={talks.presentation}
        /> 
            
        )
        })
        }

        </div>



    )
};
export default withRouter (talks);